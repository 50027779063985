<template>
  <div class="index-container">
    <div class="bread">
      <span @click="goHome">操作台 /</span>
      <span @click="goBack" class="gray">车辆出租 / </span>
      <span>{{state}}详情</span>
    </div>
    <div class="detail-body">
      <h5 class="title">车辆出售详情</h5>
      <div class="date-body">
        <el-form ref="form" :model="carInfo" label-position="right" class="info">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="6">
              <el-form-item label="车牌号" :label-width="formLabelWidth" >
                <el-input v-model="carInfo.carNum" autocomplete="off" placeholder="未设置" :disabled="disable"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="6">
              <el-form-item label="品牌型号" :label-width="formLabelWidth" >
                <el-input v-model="carInfo.carMode" autocomplete="off" placeholder="未设置" :disabled="disable"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="6">
              <el-form-item label="注册日期" :label-width="formLabelWidth" >
                <el-input v-model="carInfo.registerTime" autocomplete="off" placeholder="未设置" :disabled="disable"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="6">
              <el-form-item label="运行平台" :label-width="formLabelWidth" >
                <el-input  v-model="carInfo.operPlatform" autocomplete="off" placeholder="未设置" :disabled="disable"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="6">
              <el-form-item label="公里数" :label-width="formLabelWidth" >
                <el-input  v-model="carInfo.kilometers" autocomplete="off" placeholder="未设置" :disabled="disable">
                  <span slot="append">Km</span>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="6">
              <el-form-item label="营运性质" :label-width="formLabelWidth" >
                <el-input  v-model="carInfo.carServiceType" autocomplete="off" placeholder="未设置" :disabled="disable">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="6">
              <el-form-item label="年检到期" :label-width="formLabelWidth" >
                <el-input  v-model="carInfo.yearCheckUpTimeScope" autocomplete="off" placeholder="未设置" :disabled="disable"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="6">
              <el-form-item label="保险到期" :label-width="formLabelWidth" >
                <el-input  v-model="carInfo.insureTime" autocomplete="off" placeholder="未设置" :disabled="disable"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-form ref="form" :model="carInfo" label-position="right" class="info" v-if="carState!=1">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="12" :md="6" :lg="4" :xl="3">
              <el-form-item label="支付方式" :label-width="formLabelWidth" >
                <el-radio-group v-model="carInfo.hireMode" >
                  <el-radio label="周租" v-if="carInfo.hireMode=='周租'">周租</el-radio>
                  <el-radio label="月租" v-if="carInfo.hireMode=='月租'">月租</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="6">
              <el-form-item label="租期" :label-width="formLabelWidth" >
                <el-input  v-model="carInfo.hireTimeType" autocomplete="off" placeholder="未设置" :disabled="disable"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="6">
              <el-form-item label="押金" :label-width="formLabelWidth" >
                <el-input  v-model="carInfo.hireMoney" autocomplete="off" placeholder="未设置" :disabled="disable">
                  <span slot="append">元</span>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="6">
              <el-form-item label="租金" :label-width="formLabelWidth" >
                <el-input  v-model="carInfo.depositMoney" autocomplete="off" placeholder="未设置" :disabled="disable">
                  <span slot="append">元</span>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>

        </el-form>
        <el-form ref="form" :model="carInfo" label-position="right" class="info" v-if="carState!=1">
          <el-row :gutter="20">
            <el-col :span="13">
              <el-col :span="12">
                <el-form-item label="联系人" :label-width="formLabelWidth" prop="carNum">
                  <el-input v-model="carInfo.liaisonName" autocomplete="off" placeholder="未设置" disabled></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="联系电话" :label-width="formLabelWidth" prop="liaisonPhone">
                  <el-input v-model="carInfo.liaisonPhone" autocomplete="off" placeholder="未设置" disabled></el-input>
                </el-form-item>
              </el-col>
            </el-col>
            <el-col :span="13">
              <el-form-item label="备注" :label-width="formLabelWidth" disabled>
                <el-input v-model="carInfo.remark" autocomplete="off" placeholder="未设置" disabled
                          type="textarea"
                          rows="6"
                          maxlength="200"
                          show-word-limit
                          resize="none"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import {detailOne} from "@/utils/api/car";
import helper from "@/utils/helper";
export default {
  name: "zc",
  data(){
    return{
      formLabelWidth:"80px",
      labelPosition:"right",
      carState:1,
      state:"空闲",
      pList:[],//车牌列表
      cList:[],//联系人列表
      hList:[],//负责人列表,
      disable:true,
      carInfo:{

      }
    }
  },
  created() {
    if (!this.$route.params){
      this.$baseMessage('缺少车辆信息','warning')
      setTimeout(() => {
        history.back();
      }, 2000);
      return
    }
    let state= this.carState = this.$route.params.state
    if (state==1){
      this.state="空闲中"
    }
    if (state==2){
      this.state="出租中"
    }
    if (state==3){
      this.state="已出租"
    }
    let car = this.$route.params.id
    this.getDetail(car)
  },
  methods:{
    goBack(){
      this.$router.push({name:'租车'})
    },
    goHome(){
      this.$router.push({name:'操作台'})
    },
    getDetail(id){
      let  form = {
        id: id
      }
      detailOne(form).then(res => {
        if(res.code == 0){
          this.carInfo = res.data.carInfo
          this.carInfo.registerTime =helper.dealTime(this.carInfo.registerTime)
          this.carInfo.yearCheckUpTimeScope= helper.dealTime(this.carInfo.yearCheckUpTimeScope)
          this.carInfo.insureTime= helper.dealTime(this.carInfo.insureTime)
          this.carInfo.ascription = this.carInfo.ascription==1 ?"企业":"个人"
          this.carInfo.carServiceType = this.carInfo.carServiceType==1 ?"营运":"非营运"
          if(this.carInfo.operPlatform==1){
            this.carInfo.operPlatform="美团平台"
          }
          if(this.carInfo.operPlatform==2){
            this.carInfo.operPlatform="滴滴平台"
          }
          if(this.carInfo.operPlatform==3){
            this.carInfo.operPlatform="其他平台"
          }
        }else{
          this.$baseMessage(res.msg,'error')
          setTimeout(() => {
            history.back();
          }, 100);
        }
      })
    },
  }
}
</script>

<style lang="less">
.detail-body{
  background-color: #FFFFFF;
  .title{
    height: 22px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 22px;
    padding: 30px;
  }
  .date-body{
    .el-form{
      .el-row{
        .el-form-item{
          .el-form-item__label{
            font-size: 14px;
            font-weight: 400;
            color: #333333;
          }
          .el-input.is-disabled .el-input__inner{
            border: none;
            background: #FAFAFA;
            border-radius: 2px;
            color: #333333 ;
          }
          .el-input-group__append, .el-input-group__prepend{
            background: #FAFAFA;
            color: #333333 ;
            border: none;
          }
          .el-textarea.is-disabled .el-textarea__inner{
            background: #FAFAFA;
            color: #333333 ;
            border: none;
          }
        }
      }
    }
  }
}


</style>
